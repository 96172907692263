<template>
    <div id="app">
        <div class="body fixed mobile">
            <!-- class: fixed, mobile -->
            <!-- Loading Spinner -->
            <!-- <div class="spinner"><div><div></div></div></div> -->
            <!-- Header -->
            <section class="header point_history">
                <a
                    class="icon iconButton third"
                    @click.prevent="$router.push('/home')"
                >
                    <font-awesome-icon
                        icon="fa-solid fa-chevron-left"
                        size="lg"
                    ></font-awesome-icon>
                </a>
                <span class="fL">我的紅利桃子</span>
                <div class="icon iconButton"></div>
            </section>
            <!-- Main -->
            <section class="main point_history">
                <!-- Tabs -->
                <div class="tabs line fM rounded">
                    <a class="tab flexV width center active" @click.prevent>
                        <span class="fS">紅利桃子</span>
                        <div class="flexH">
                            <div class="img">
                                <img src="@/assets/icon/point.svg" />
                            </div>
                            <span class="fM bold">{{
                                displayPointBalance
                            }}</span>
                        </div>
                    </a>
                    <div class="lineV"></div>
                    <a
                        class="tab flexV width center"
                        @click.prevent="$router.push('/point-list')"
                    >
                        <span class="fS"
                            >{{ displayLastestEndDateOfPoints }}到期</span
                        >
                        <div class="flexH">
                            <div class="img">
                                <img src="@/assets/icon/point.svg" />
                            </div>
                            <span class="fM bold">{{
                                displayLastestBalanceOfPoints
                            }}</span>
                        </div>
                    </a>
                </div>
                <!-- Filter -->
                <div class="filter flexH width">
                    <a
                        class="flexH width middle rounded"
                        @click.prevent="timeFilterShow()"
                    >
                        <span class="fM">時間區間</span>
                        <div class="icon third">
                            <font-awesome-icon
                                icon="fa-solid fa-caret-down"
                                size="xs"
                            />
                        </div>
                    </a>
                    <div class="lineV"></div>
                    <div class="flexH width middle">
                        <div class="dropdown rounded">
                            <select
                                name="type"
                                class="fM rounded"
                                v-model="filterbyType"
                                @change="filterByTypeHandler"
                            >
                                <option value="A">全部類型</option>
                                <option value="D">獲得點數</option>
                                <option value="C">點數消費</option>
                            </select>
                            <div class="arrow third">
                                <font-awesome-icon
                                    icon="fa-solid fa-caret-down"
                                    size="xs"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="lineV"></div>
                    <div class="flexH width middle">
                        <div class="dropdown rounded">
                            <select
                                name="type"
                                class="fM rounded"
                                v-model="filterbyTime"
                                @change="filterByTimeHandler"
                            >
                                <option value="tdesc">新到舊</option>
                                <option value="tasc">舊到新</option>
                                <option value="pdesc">點數大到小</option>
                                <option value="pasc">點數小到大</option>
                            </select>
                            <div class="arrow third">
                                <font-awesome-icon
                                    icon="fa-solid fa-caret-down"
                                    size="xs"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 紀錄 -->
                <div class="records flexV width padding">
                    <div
                        v-for="(transaction, index) in displayTransactions"
                        :key="'transaction' + index"
                    >
                        <div class="record flexH width between margin">
                            <div class="flexV">
                                <span class="fL">{{
                                    $t(transaction.transactionType)
                                }}</span>
                                <span class="fM gray">{{
                                    transaction.note
                                }}</span>
                            </div>
                            <div class="flexV end">
                                <div class="flexH">
                                    <div class="img">
                                        <img src="@/assets/icon/point.svg" />
                                    </div>
                                    <span
                                        :class="
                                            pointClass(transaction.entryType)
                                        "
                                        >{{
                                            displaySignOfPoint(
                                                transaction.entryType
                                            )
                                        }}{{ transaction.pointAmount }}</span
                                    >
                                </div>
                                <span class="fM gray">{{
                                    tsToDatetime(transaction.transactionTs)
                                }}</span>
                            </div>
                        </div>
                        <div class="lineH"></div>
                    </div>
                </div>
            </section>
            <section class="footer point_history">
                <a
                    class="button rounded"
                    @click.prevent="$router.push('/scanner')"
                >
                    <div class="icon">
                        <img src="@/assets/icon/scanner.svg" />
                    </div>
                    <span class="fM">使用紅利點數</span>
                </a>
            </section>
            <!-- Popup (時間區間) -->
            <section id="popTime" class="popup popTime point_history">
                <div class="popBody">
                    <a class="close" @click.prevent="timeFilterClose()"
                        ><font-awesome-icon
                            icon="fas fa-times"
                        ></font-awesome-icon
                    ></a>
                    <section class="popHeader">
                        <span class="fL">時間區間</span>
                    </section>
                    <section class="popMain">
                        <!-- Tabs -->
                        <div class="tabs rounded fS">
                            <a
                                :class="['tab', 'rounded', {active: timeFilterOption === 'Weekly'}]"
                                @click.prevent="
                                    changeTimeFilterOption('Weekly')
                                "
                            >
                                <span>近一週</span>
                            </a>
                            <a
                                :class="['tab', 'rounded', {active: timeFilterOption === 'Monthly'}]"
                                @click.prevent="
                                    changeTimeFilterOption('Monthly')
                                "
                            >
                                <span>近一個月</span>
                            </a>
                            <a
                                :class="['tab', 'rounded', {active: timeFilterOption === 'Yearly'}]"
                                @click.prevent="
                                    changeTimeFilterOption('Yearly')
                                "
                            >
                                <span>近一年</span>
                            </a>
                            <a
                                :class="['tab', 'rounded', {active: timeFilterOption === 'Customize'}]"
                                @click.prevent="
                                    changeTimeFilterOption('Customize')
                                "
                            >
                                <span>自訂時間</span>
                            </a>
                        </div>
                        <!-- Date -->
                        <div class="input rounded" v-show="timeFilterOption == 'Customize'">
                            <!-- class: fake / error -->
                            <input
                                :class="['fM', 'date', {error: selectStartErr}]"
                                type="date"
                                name="startDate"
                                v-model="selectStart"
                                @change="selectStartChange"
                            />
                            <!-- <input class="fM time" type="time" name="startTime"> -->
                            <span class="fL">-</span>
                            <input 
                                :class="['fM', 'date', {error: selectEndErr}]"
                                type="date" name="endDate" 
                                v-model="selectEnd"
                                @change="selectEndChange"
                            />
                        </div>
                    </section>
                    <section class="popFooter">
                        <a
                            class="button rounded cancel white"
                            @click.prevent="timeFilterCancel()"
                            ><span class="fM">取消</span></a
                        >
                        <a
                            class="button rounded submit"
                            @click.prevent="timeFilterConfirm()"
                            ><span class="fM">確認</span></a
                        >
                    </section>
                </div>
            </section>
        </div>
    </div>
</template>
<script>
import moment from "moment-timezone";
import { mapState, mapGetters } from "vuex";

export default {
    name: "Record",
    data() {
        return {
            apiHost: process.env.VUE_APP_API_HOST,
            merchantId: process.env.VUE_APP_MERCHANT_ID,
            transactions: {},
            filterbyType: "A",
            filterbyTime: "tdesc",
            originTransactions: {},
            timeFilterStart: null,
            timeFilterEnd: null,
            timeFilterOption: "Monthly",
            selectStart: this.tsToDate(Math.floor(Date.now() / 1000) - 1 * 60 * 60 * 24 * 30),
            selectEnd: this.tsToDate(Math.floor(Date.now() / 1000)),
            selectStartErr: false,
            selectEndErr: false
        };
    },
    beforeRouteEnter(to, from, next) {
        // called before the route that renders this component is confirmed.
        // does NOT have access to `this` component instance,
        // because it has not been created yet when this guard is called!
        console.log(to, from);
        next((vm) => {
            let that = vm;
            console.log("user ", that.user);
            if (that.user && that.user.userId) {
                //
            } else {
                next("/error");
            }
        });
    },
    created() {
        this.getUserPoints()
            .then((getUserPointsRes) => {
                console.log("getUserPointsRes: ", getUserPointsRes);
                this.$store.commit("updateUserPoints", getUserPointsRes);
                return this.getTransactions();
            })
            .then((getTransactionsRes) => {
                this.transactions = JSON.parse(
                    JSON.stringify(getTransactionsRes)
                );
                this.originTransactions = JSON.parse(
                    JSON.stringify(getTransactionsRes)
                );
            })
            .catch((err) => {
                console.log("err: ", err);
                this.router.push("/error");
            });
    },
    mounted() {},
    computed: {
        ...mapState(["user"]),
        ...mapGetters({
            userPoints: "getTyUserPoints"
        }),
        displayLastestEndDateOfPoints() {
            return this.lastestEndOfPoint ? this.tsToDate(this.lastestEndOfPoint.endTs) : "";

            // if (
            //     this.userPoints &&
            //     this.userPoints.pointPeriods &&
            //     this.userPoints.pointPeriods.length > 0
            // ) {
            //     return this.tsToDate(this.userPoints.pointPeriods[0].endTs);
            // } else {
            //     return "";
            // }
        },
        displayLastestBalanceOfPoints() {
            return this.lastestEndOfPoint ? this.lastestEndOfPoint.pointBalance : 0;
            // if (
            //     this.userPoints &&
            //     this.userPoints.pointPeriods &&
            //     this.userPoints.pointPeriods.length > 0
            // ) {
            //     return this.userPoints.pointPeriods[0].pointBalance;
            // } else {
            //     return 0;
            // }
        },
        displayPointBalance() {
            return this.userPoints && this.userPoints.pointBalance
                ? this.userPoints.pointBalance
                : 0;
        },
        displayTransactions() {
            if (
                this.transactions.records &&
                this.transactions.records.length > 0
            ) {
                return this.transactions.records;
            } else {
                return [];
            }
        },
        lastestEndOfPoint() {
            if (
                this.userPoints &&
                this.userPoints.pointPeriods &&
                this.userPoints.pointPeriods.length > 0
            ) {
                let sorted = [...this.userPoints.pointPeriods];
                sorted.sort(function(a, b) {
                    return a.endTs - b.endTs;
                })
                const latestEndOfPoint = sorted.find(p => p.endTs >= moment().unix());
                return latestEndOfPoint;
            }
            return null;
        }
    },
    methods: {
        pointClass(entryType) {
            return [
                "fL",
                "bold",
                { error: entryType == "C" },
                { success: entryType == "D" }
            ];
        },
        displaySignOfPoint(entryType) {
            return entryType == "D" ? "+" : "-";
        },
        tsToDatetime(ts) {
            return moment(ts * 1000).format("YYYY-MM-DD HH:mm:ss");
        },
        tsToDate(ts) {
            return moment(ts * 1000).format("YYYY-MM-DD");
        },
        getUserPoints() {
            var config = {
                method: "get",
                url: `${this.apiHost}/points/v1/merchants/${this.merchantId}/members/${this.user.userId}/points`,
                headers: {}
            };

            return this.$http(config)
                .then(function (response) {
                    return response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        dummyTransactions() {
            let result = {
                total: 3,
                records: [
                    {
                        entryId: 144,
                        pointAmount: 10,
                        entryType: "C",
                        note: "花錢",
                        transactionId: 74,
                        transactionEntityId: 270,
                        transactionTs: 1650021907,
                        transactionType: "Consumption-Discount",
                        merchantOrderId: 41
                    },
                    {
                        entryId: 146,
                        pointAmount: 10,
                        entryType: "C",
                        note: "consumption",
                        transactionId: 75,
                        transactionEntityId: 270,
                        transactionTs: 1650033376,
                        transactionType: "Consumption-Discount",
                        merchantOrderId: 42
                    },
                    {
                        entryId: 148,
                        pointAmount: 10,
                        entryType: "C",
                        note: "consumption",
                        transactionId: 76,
                        transactionEntityId: 270,
                        transactionTs: 1650033478,
                        transactionType: "Consumption-Discount",
                        merchantOrderId: 43
                    }
                ]
            };
            let promise = new Promise((resolve) => {
                setTimeout(() => {
                    resolve({ data: result });
                }, 300);
            });
            return promise;
        },
        callTransactionsAPI(start, end) {
            let endTime = end || Math.floor(Date.now() / 1000);
            let startTime = start || endTime - 1 * 60 * 60 * 24 * 30;

            var config = {
                method: "get",
                url: `${this.apiHost}/points/v1/merchants/${this.merchantId}/members/${this.user.userId}/points/${this.userPoints.pointCode}/ledgers?from_ts=${startTime}&to_ts=${endTime}`,
                headers: {}
            };
            return this.$http(config)
                .then(function (response) {
                    return response;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getTransactions(start, end) {
            return (
                this.callTransactionsAPI(start, end)
                    // return this.dummyTransactions()
                    .then((res) => {
                        return res.data;
                    })
                    .catch((err) => {
                        console.log("err:", err);
                    })
            );
        },
        goBack() {
            window.history.length > 1
                ? this.$router.go(-1)
                : this.$router.push("/");
        },
        filterByTypeHandler() {
            switch (this.filterbyType) {
                case "A":
                    this.transactions = JSON.parse(
                        JSON.stringify(this.originTransactions)
                    );
                    break;
                case "C":
                    this.transactions.records =
                        this.originTransactions.records.filter(
                            (t) => t.entryType === "C"
                        );
                    break;
                case "D":
                    this.transactions.records =
                        this.originTransactions.records.filter(
                            (t) => t.entryType === "D"
                        );
                    break;

                default:
                    break;
            }
            this.filterByTimeHandler();
        },
        filterByTimeHandler() {
            function tdesc(b, a) {
                return a.transactionTs - b.transactionTs;
            }
            function tasc(a, b) {
                return a.transactionTs - b.transactionTs;
            }
            function pdesc(b, a) {
                return a.pointAmount - b.pointAmount;
            }
            function pasc(a, b) {
                return a.pointAmount - b.pointAmount;
            }
            switch (this.filterbyTime) {
                case "tdesc":
                    this.transactions.records.sort(tdesc);
                    break;
                case "tasc":
                    this.transactions.records.sort(tasc);
                    break;
                case "pdesc":
                    this.transactions.records.sort(pdesc);
                    break;
                case "pasc":
                    this.transactions.records.sort(pasc);
                    break;
                default:
                    break;
            }
        },
        timeFilterShow() {
            popTime.style.display = "flex";
        },
        timeFilterHide() {
            popTime.style.display = "none";
        },
        timeFilterClose() {
            this.timeFilterHide();
        },
        timeFilterCancel() {
            this.timeFilterHide();
        },
        timeFilterConfirm() {
            if(this.timeFilterOption == "Customize") {
                console.log(this.selectStart)
                this.selectStartErr = this.selectStart ? false : true;
                this.selectEndErr = this.selectEnd ? false : true;
                if(this.selectStartErr || this.selectEndErr ) return;
            }
            this.changeTimeFilterValue();
            let start = this.timeFilterStart;
            let end = this.timeFilterEnd;

            this.getTransactions(start, end)
                .then((getTransactionsRes) => {
                    this.transactions = JSON.parse(
                        JSON.stringify(getTransactionsRes)
                    );
                    this.originTransactions = JSON.parse(
                        JSON.stringify(getTransactionsRes)
                    );

                    this.filterByTypeHandler();
                    this.filterByTimeHandler();
                    this.timeFilterHide();
                })
                .catch((err) => {
                    console.log("err: ", err);
                    this.router.push("/error");
                });
        },
        changeTimeFilterOption(option) {
            this.timeFilterOption = option;
        },
        changeTimeFilterValue() {
            switch (this.timeFilterOption) {
                case "Weekly":
                    this.timeFilterEnd = Math.floor(Date.now() / 1000);
                    this.timeFilterStart = this.timeFilterEnd - 1 * 60 * 60 * 24 * 7;
                    break;
                case "Monthly":
                    this.timeFilterEnd = Math.floor(Date.now() / 1000);
                    this.timeFilterStart = this.timeFilterEnd - 1 * 60 * 60 * 24 * 30;
                    break;
                case "Yearly":
                    this.timeFilterEnd = Math.floor(Date.now() / 1000);
                    this.timeFilterStart = this.timeFilterEnd - 1 * 60 * 60 * 24 * 365;
                    break;
                case "Customize":
                    this.timeFilterEnd = this.dateToTs(this.selectEnd) > (Date.now() / 1000) ? Date.now() / 1000 : this.dateToTs(this.selectEnd);
                    this.timeFilterStart = this.dateToTs(this.selectStart)
                    break;
                default:
                    break;
            }
        },
        dateToTs(date) {
           return new Date(date).getTime() / 1000;
        },
        selectStartChange() {
            this.selectStartErr = false;
        },
        selectEndChange() {
            this.selectEndErr = false;
        },
    },
    // wathc: {
    //     "userPoints.pointCode": function (val) {
    //         console.log("watch userPoints: ", val);
    //     }
    // }
};
</script>
